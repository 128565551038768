import { z } from 'zod'
import { create } from 'zustand'
import { Analytics } from '@cerifi/analytics'
import { signupBodySchema } from '@cerifi/ecomm/bigcommerce/schemas/auth'
import { AddressFields } from '@cerifi/ecomm/bigcommerce/types/address'
import {
  Cart,
  CartItemBody,
  SelectedOption,
} from '@cerifi/ecomm/bigcommerce/types/cart'
import { Customer } from '@cerifi/ecomm/bigcommerce/types/customer'
import { Product } from '@cerifi/ecomm/bigcommerce/types/product'
import { CartMetaField } from '@cerifi/ecomm/server'
import { ariaSpeak } from '@cerifi/utils/aria-speak'
import {
  clearCartCookie,
  clearCookie,
  getCookie,
} from '@cerifi/utils/client-cookie'
import { fetcher } from '@cerifi/utils/fetcher'
import { isClient } from '@cerifi/utils/is-client'

import useNotificationStore from '../notification.store'
import { associateGuestCart, mergeCart } from './merge-cart'
import { ApiOrderKeeper, ApiOrderKey } from './utils'

type SignupBodySchema = z.infer<typeof signupBodySchema>

function handleError(e: any) {
  useNotificationStore.getState().handleError(e?.message || e)
}

interface AccountStore {
  isLoadingCustomer: boolean
  isLoadingCustomerDetails: boolean
  isLoadingInit: boolean
  isLoadingCart: boolean
  customer: Customer | null
  addresses: AddressFields[]
  cart: Cart | null
  cartMetaData: CartMetaField[]
  init: () => Promise<void>
  getCustomer: () => Promise<void>
  getCustomerDetails: () => Promise<void>
  login: (data: { email: string; password: string }) => Promise<void>
  logout: () => Promise<void>
  signup: (data: SignupBodySchema) => Promise<void>
  getCart: () => Promise<void>
  nukeCart: () => void
  applyCouponCode: (couponCode: string) => Promise<void>
  deleteCouponCode: (couponCode: string) => Promise<void>
  addItemToCart: (data: {
    product: Product
    quantity: number
    variantId: string
    /** For example, Enrollment metafields such as University and Date of Birth */
    metaFields?: Array<{
      key: string
      value: string
    }>
    optionsSelected?: SelectedOption[]
  }) => Promise<void>
  removeItemFromCart: (data: { itemId: string }) => Promise<void>
  updateItemInCart: (data: {
    cartId: string
    itemId: string
    item: CartItemBody
  }) => Promise<void>
  getAddresses: () => Promise<void>
  addAddresses: (
    addresses: AddressFields[],
    isFirstDefault: boolean
  ) => Promise<void>
  removeAddress: (id: number) => Promise<void>
  updateAddress: (address: AddressFields, isDefault: boolean) => Promise<void>
  updateCustomer: (customer: Customer) => Promise<void>
  updatePassword: (data: {
    currentPassword: string
    newPassword: string
  }) => Promise<void>
  fetchCartMetaData: () => Promise<void>
}

let cartKey: string
/**
 * Holds/handles all global information about the customer/account:
 * customer info, account info, cart, login/logout functionality, etc...
 */
const useAccountStore = create<AccountStore>((set, get) => ({
  isLoadingInit: true,
  isLoadingCart: true,
  isLoadingCustomer: true,
  isLoadingCustomerDetails: false,
  customer: null,
  addresses: [],
  cart: null,
  cartMetaData: [],
  init: async () => {
    set({
      isLoadingInit: true,
    })
    try {
      // on site load, first try to get the logged in customer
      await Promise.all([get().getCustomer(), get().getCart()])
    } catch (e) {
      handleError(e)
    }
    set({
      isLoadingInit: false,
    })
  },
  login: async ({ email, password }) => {
    ariaSpeak('logging in')
    set({
      cart: null,
      customer: null,
    })
    try {
      // this will update the bigcommerce customer cookie
      const response = await fetcher('/api/commerce/login', {
        method: 'POST',
        body: { email, password },
      })
      // uses that cookie to get the customer
      await get().getCustomer()

      const customer = get().customer
      // if the customer has a guest cart (before the logged in)
      // it will be merged into their existing cart
      if (customer) {
        await mergeCart(customer)
      }

      await get().getCart()
      const userIdHash = response.data.userIdHash
      Analytics.SendUserId(userIdHash)
      Analytics.Registration(userIdHash)
    } catch (e) {
      throw e
    }
  },
  getCustomer: async () => {
    set({
      isLoadingCustomer: true,
    })
    try {
      const customerCookie = getCookie('SHOP_TOKEN')

      if (!customerCookie) {
        set({
          customer: null,
          isLoadingCustomer: false,
        })
        return
      }

      const response = await fetcher<{ customer: Customer }>(
        '/api/commerce/customer',
        {
          method: 'GET',
        }
      )

      if (!response?.customer) {
        clearCookie('SHOP_TOKEN')
      }

      set({
        customer: response?.customer,
        isLoadingCustomer: false,
      })
    } catch (e) {
      set({
        customer: null,
        isLoadingCustomer: false,
      })
    }
  },
  getCustomerDetails: async () => {
    set({
      isLoadingCustomerDetails: true,
    })
    try {
      const response = await fetcher<Customer>(
        `/api/commerce/customer-details?id=${get().customer?.id}`,
        {
          method: 'GET',
        }
      )
      set({
        customer: {
          ...get().customer,
          accepts_product_review_abandoned_cart_emails:
            response?.accepts_product_review_abandoned_cart_emails,
        } as Customer,
        isLoadingCustomerDetails: false,
      })
    } catch (e) {
      set({
        customer: null,
        isLoadingCustomerDetails: false,
      })
    }
  },

  getCart: async () => {
    try {
      set({
        isLoadingCart: true,
      })

      const cartCookie = getCookie(
        process.env.NEXT_PUBLIC_BIGCOMMERCE_CART_COOKIE!
      )

      if (!cartCookie) {
        set({
          isLoadingCart: false,
          cart: null,
        })
        return
      }

      const cart = await fetcher('/api/commerce/cart', { method: 'GET' })

      if (!cart) {
        clearCookie(process.env.NEXT_PUBLIC_BIGCOMMERCE_CART_COOKIE!)
      }

      set({
        isLoadingCart: false,
        cart: cart,
      })
    } catch (e) {
      handleError(e)
    }
  },
  nukeCart: () => {
    try {
      clearCartCookie()
      set({
        customer: null,
        cart: null,
      })
    } catch (e) {
      handleError(e)
    }
  },
  logout: async () => {
    ariaSpeak('logging out')
    try {
      await fetcher('/api/commerce/logout', {
        method: 'GET',
      })
      clearCartCookie()

      set({
        customer: null,
        cart: null,
        addresses: [],
      })
    } catch (e) {
      handleError(e)
    }
  },
  signup: async (data) => {
    ariaSpeak('signing up')
    try {
      const response = await fetcher('/api/commerce/register', {
        method: 'POST',
        body: {
          ...data,
        },
      })

      set({
        customer: null,
        cart: null,
      })

      await get().init()

      const cartId = get().cart?.id
      const customerId = get().customer?.id
      if (!customerId) {
        throw new Error(
          'Uh oh! Something went wrong after creating your account.'
        )
      }
      if (cartId) {
        await associateGuestCart(cartId, customerId)
        await get().getCart()
      }

      const userIdHash = response.data.userIdHash
      Analytics.SendUserId(userIdHash)
      Analytics.Registration(userIdHash)
    } catch (e) {
      throw e
    }
  },
  applyCouponCode: async (couponCode) => {
    set({
      isLoadingCart: true,
    })

    try {
      await fetcher<Cart>(`/api/commerce/coupon`, {
        method: 'POST',
        body: { couponCode },
      })
        .then((cart) => {
          set({
            isLoadingCart: false,
            cart: cart,
          })
        })
        .catch((err: any) => {
          set({
            isLoadingCart: false,
          })
          if (err?.message) {
            useNotificationStore.getState().showToast({
              level: 'error',
              title: err?.message,
            })
          } else {
            throw err
          }
        })
    } catch (e) {
      set({
        isLoadingCart: false,
      })
      handleError(e)
    }
  },
  deleteCouponCode: async (couponCode) => {
    set({
      isLoadingCart: true,
    })

    const cart = await fetcher<Cart>(
      `/api/commerce/coupon?couponCode=${couponCode}`,
      {
        method: 'DELETE',
      }
    )

    set({
      isLoadingCart: false,
      cart: cart,
    })
  },
  addItemToCart: async ({
    product,
    quantity,
    variantId,
    metaFields,
    optionsSelected,
  }) => {
    ariaSpeak('adding item to cart')

    cartKey = ApiOrderKeeper.getKey(ApiOrderKey.CART)

    const item: CartItemBody = {
      productId: product.id,
      quantity: quantity,
      variantId: variantId,
      sku: product.sku,
      metaFields,
      optionsSelected,
    }

    Analytics.AddToCart(product, 'cart', quantity, variantId)

    const cart = await fetcher('/api/commerce/cart', {
      method: 'POST',
      body: { item },
    })

    if (ApiOrderKeeper.check(ApiOrderKey.CART, cartKey)) {
      set({
        cart: cart,
      })

      if (process.env.NEXT_PUBLIC_DATADOG_ENABLED) {
        const datadogRum = (await import('@datadog/browser-rum')).datadogRum
        datadogRum.setUserProperty('cartId', cart?.id)
      }

      // Ensuring that the cart is tied to this customer, bigcommerce does not do this automatically
      if (!cart.customerId || cart.customerId === '0') {
        // We need to make sure

        const customer = get().customer

        if (customer && cart.customerId !== customer.id) {
          // associate cart with customer
          await fetcher('/api/commerce/customer', {
            method: 'PUT',
            body: {
              cartId: cart.id,
              customerId: customer.id,
            },
          })

          set({
            cart: {
              ...(get().cart as Cart),
              id: customer.id,
            },
          })
        }
      }
    }
  },
  removeItemFromCart: async (data) => {
    ariaSpeak('removing item')

    try {
      const item = get().cart?.lineItems.find((i) => i.id === data.itemId)
      if (!item) {
        throw new Error('trying to remove item that is not in the cart')
      }
      Analytics.RemoveFromCart(item)

      cartKey = ApiOrderKeeper.getKey(ApiOrderKey.CART)

      const cart = await fetcher(`/api/commerce/cart?itemId=${data.itemId}`, {
        method: 'DELETE',
      })
      if (ApiOrderKeeper.check(ApiOrderKey.CART, cartKey)) {
        set({
          cart: cart,
        })
      }
    } catch (e) {
      handleError(e)
    }
  },
  updateItemInCart: async (data) => {
    ariaSpeak('updating item')
    cartKey = ApiOrderKeeper.getKey(ApiOrderKey.CART)
    try {
      const cart = await fetcher('/api/commerce/cart', {
        method: 'PUT',
        body: { ...data },
      })

      if (ApiOrderKeeper.check(ApiOrderKey.CART, cartKey)) {
        set({
          cart: cart,
        })
      }
    } catch (e) {
      handleError(e)
    }
  },
  getAddresses: async () => {
    try {
      const addresses = await fetcher<AddressFields[]>(
        '/api/commerce/address',
        {
          method: 'GET',
        }
      )
      set({
        addresses: addresses || [],
      })
    } catch (e) {
      handleError(e)
    }
  },
  addAddresses: async (addresses, isFirstDefault) => {
    ariaSpeak('adding new address')
    try {
      const data = await fetcher<AddressFields[]>('/api/commerce/address', {
        method: 'POST',
        body: {
          addresses,
          isFirstDefault: isFirstDefault,
        },
      })

      set({
        addresses: get().addresses.concat(data || []),
      })

      await get().getCustomer()

      useNotificationStore.getState().showToast({
        title: 'Address successfully updated',
        level: 'success',
        duration: 2000,
      })
    } catch (e) {
      handleError(e)
    }
  },
  removeAddress: async (id) => {
    ariaSpeak('removing address')
    try {
      await fetcher(`/api/commerce/address?itemId=${id}`, {
        method: 'DELETE',
      })
      set({
        addresses: get().addresses.filter((x) => x.id !== id),
      })
    } catch (e) {
      handleError(e)
    }
  },

  updateAddress: async (address, isDefault) => {
    ariaSpeak('address updated')

    try {
      const updated = await fetcher<AddressFields[]>('/api/commerce/address', {
        method: 'PUT',
        body: {
          item: address,
          isDefault: isDefault,
        },
      })

      if (!updated || updated.length === 0) {
        throw new Error('updated does not exist')
      }

      const updatedAddress = updated[0]

      if (!updatedAddress) {
        throw new Error('did not receive updated address')
      }

      set({
        addresses: get().addresses.map((a) =>
          a.id === updatedAddress.id ? updatedAddress : a
        ),
      })

      await get().getCustomer()

      useNotificationStore.getState().showToast({
        title: 'Success address updated',
        level: 'success',
        duration: 2000,
      })
    } catch (e) {
      handleError(e)
    }
  },
  updateCustomer: async (customer) => {
    ariaSpeak('customer updated')
    set({
      isLoadingCustomer: true,
    })

    try {
      const res = await fetcher<{ data: Customer }>('/api/commerce/customer', {
        method: 'PATCH',
        body: customer,
      })
      if (!res?.data) {
        throw new Error('Something went wrong updating the customer...')
      }
      set({
        customer: res.data,
      })
    } catch (e) {
      handleError(e)
    } finally {
      set({
        isLoadingCustomer: false,
      })
    }
  },
  updatePassword: async (data) => {
    const { currentPassword, newPassword } = data
    set({
      isLoadingCustomer: true,
    })

    ariaSpeak('updating password')
    try {
      await fetcher('/api/commerce/customer', {
        method: 'POST',
        body: {
          email: get().customer?.email,
          currentPassword,
          newPassword,
        },
      })

      useNotificationStore.getState().showToast({
        title: 'Success password updated',
        level: 'success',
        duration: 2000,
      })
    } catch (e) {
      handleError(e)
    } finally {
      set({
        isLoadingCustomer: false,
      })
    }
  },
  fetchCartMetaData: async () => {
    const metaFields = await fetcher<CartMetaField[]>(
      '/api/commerce/cart-metafield',
      {
        method: 'GET',
      }
    )
    set({
      cartMetaData: metaFields || [],
    })
  },
}))

if (isClient()) {
  useAccountStore.getState().init()
}

export default useAccountStore
