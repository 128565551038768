import { isClient } from './is-client'
import { ResponseError } from './response-error'

type HTTPMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH'

type FetcherConfig = {
  readonly method: HTTPMethod
  readonly body?: object
  readonly config?: RequestInit
}

/**
 * Generic provider for making API calls with native Fetch
 */
export const fetcher = async function fetcher<TResult = any>(
  path: string,
  { method, body, config }: FetcherConfig
): Promise<TResult | null> {
  try {
    const response = await fetch(path, {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        // 'x-device-fingerprint': getBrowserFingerprint(),
      },
      credentials: 'include',
      method,
      ...(body && { body: JSON.stringify(body) }),
    })

    if (response.ok) {
      const data = await response.json()
      return data satisfies TResult
    }

    // TODO: HANDLE UNAUTHORIZED ERRORS
    const errorResponse = await response.json()

    if (errorResponse?.error?.name === 'CommerceApiError') {
      throw new ResponseError(errorResponse.error.message)
    }

    if (errorResponse?.errors[0]?.message) {
      throw new ResponseError(errorResponse.errors[0].message, response.status)
    }

    throw new ResponseError(response.statusText, response.status)
  } catch (err: any) {
    if (err instanceof ResponseError && isClient()) throw err

    if (isClient()) throw new ResponseError('Something went wrong')

    // if (isClient()) {
    //   useNotificationStore?.getState()?.handleError('Something went wrong')
    // }

    throw new ResponseError('Something went wrong while fetching')
  }
}
