import { serialize } from 'cookie'

import { UniversityEnum } from './enums-and-constants/university-enum'

const ONE_DAY = 60 * 60 * 24

export function clearCookie(name: string) {
  document.cookie = serialize(name, '', {
    maxAge: -1,
    path: '/',
  })
}

export function getCookie(name: string) {
  if (!document) return null
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift()
}

export function setCartCookieClient(cartId: string) {
  if (!process.env.NEXT_PUBLIC_BIGCOMMERCE_CART_COOKIE) {
    throw new Error('could not find cart cookie in env')
  }

  document.cookie = serialize(
    process.env.NEXT_PUBLIC_BIGCOMMERCE_CART_COOKIE,
    cartId,
    {
      maxAge: ONE_DAY * 30,
      path: '/',
      sameSite: 'lax',
    }
  )
}

export function setUniversityCookie(entryId: string) {
  document.cookie = serialize(UniversityEnum.UNIVERSITY_AFFILIATE, entryId, {
    path: '/',
    sameSite: 'lax',
  })
}

/** The cart id is held in a cookie, which is used to fetch the cart from bigcommerce */
export function clearCartCookie() {
  if (!process.env.NEXT_PUBLIC_BIGCOMMERCE_CART_COOKIE) {
    throw new Error('could not find cart cookie in env')
  }
  clearCookie(process.env.NEXT_PUBLIC_BIGCOMMERCE_CART_COOKIE)
}
