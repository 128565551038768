import { Cart, LineItem } from '@cerifi/ecomm/bigcommerce/types/cart'
import { BCProduct, Product } from '@cerifi/ecomm/bigcommerce/types/product'
import { getLogger } from '@cerifi/logger'

import { DataLayer } from './gtm/data-layer'
import { MetaPixel } from './pixel/meta-pixel'

declare global {
  interface Window {
    dataLayer: any
    fbq: any
  }
}

/**
 *
 * @returns hooks related to GA4 Data Layer/gtag
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#search
 */
export class Analytics {
  private static logger = getLogger('analytics')

  /**
   * Measure when an item detail page is viewed
   */
  public static ViewItem = (
    id: string,
    sku: string,
    name: string,
    price: number
  ) => {
    DataLayer.ViewItem(id, sku, name, price)
    MetaPixel.ViewContent(sku, name, price)
  }

  public static SelectPromotion = () => {
    // TODO
  }

  public static ViewPromotion = () => {
    // TODO
  }

  public static SelectItem = (item: Product, listName: string) => {
    DataLayer.SelectItem(item, listName)
  }

  public static AddToWishList = (item: Product, variantId: string) => {
    DataLayer.AddToWishList(item, variantId)
  }

  public static AddToCart = (
    item: Product,
    listName: string,
    quantity: number,
    variantId: string
  ) => {
    DataLayer.AddToCart(item, listName, quantity, variantId)
    MetaPixel.AddToCart(item.sku || item.id, item.name, item.price.value)
  }

  public static RemoveFromCart = (item: LineItem) => {
    DataLayer.RemoveFromCart(item)
  }

  public static BeginCheckout = (cart: Cart) => {
    DataLayer.BeginCheckout(cart)
    MetaPixel.InitiateCheckout(cart)
  }

  public static ShippingInfo = (
    cart: Cart,
    value: number,
    shippingTier: string,
    coupon: string
  ) => {
    DataLayer.ShippingInfo(cart, value, shippingTier, coupon)
  }

  public static PaymentInfo = (cart: Cart, value: number, coupon: string) => {
    DataLayer.PaymentInfo(cart, value, coupon)
  }

  public static PurchaseOrRefund = (
    eventName: 'purchase' | 'refund',
    cart: Cart,
    orderId: string,
    tax: number,
    total: number,
    shipping: number,
    coupon?: string
  ) => {
    DataLayer.PurchaseOrRefund(
      eventName,
      cart,
      orderId,
      tax,
      total,
      shipping,
      coupon
    )

    if (eventName === 'purchase') {
      MetaPixel.Purchase(cart, total)
    }
  }

  public static SendUserId = (hash: string) => {
    DataLayer.SendUserId(hash)
  }

  public static Registration = (hash: string) => {
    DataLayer.Registration(hash)
    MetaPixel.CompleteRegistration()
  }

  public static Login = (hash: string) => {
    DataLayer.Login(hash)
  }

  public static Search = (searchTerm: string) => {
    DataLayer.Search(searchTerm)
  }

  public static CustomEvent = (eventName: string, eventObject: any) => {
    DataLayer.CustomEvent(eventName, eventObject)
  }
}
