import { v4 } from 'uuid'

export enum ApiOrderKey {
  CART = 'cart',
}

/**
 * `const key = ApiOrderKeeper.getKey(ApiOrderKey.CART);`
 *
 * `axios.post('/cart', cart).then((c) => {`
 *  `if (ApiOrderKeeper.check(ApiOrderKey.CART, key)) {`
 *    `cartStore.setCart(cart);`
 *  `}`
 * `})`
 *
 *
 * This tool helps keep Api responses in order. It allows you to
 * easily ignore old api requests if a newer one has been sent. It
 * can be used for the same reason you would use switchMap in RXJS
 *
 * example: You have a bunch of calls to save your cart going out.
 * You want to make sure you only listen to the response of the last
 * call, but you cannot be absolutely certain that the calls will
 * be returned in the same order they are sent, because they are async.
 *
 * so get a key with getKey() before you send the request, and only do
 * stuff with that data if check() returns true in the response
 * (which only happens for the last call)
 *
 * This will keep the api calls from getting out of order, and keep your
 * cart from flashing with different states after you make a lot of
 * api calls in quick succession (ei, removing a bunch of items quickly)
 *
 *
 */
export class ApiOrderKeeper {
  private static keyMap: {
    [key: string]: string
  } = {}

  constructor() {}

  /** gets a unique key for this api call. Pass that key into the check method after your api
   * call to see if it is the last call.
   */
  public static getKey(apiName: ApiOrderKey): string {
    const key = v4()
    this.keyMap[apiName] = key
    return key
  }

  /** Returns true if your api call was the last one sent out
   * @param apiName the api call you are watching
   * @param key the key you got from getKey when you sent out your api call
   * @returns true if that key matches the last api call out, false if not (which means out of order)
   */
  public static check(apiName: ApiOrderKey, key: string): boolean {
    return key === this.keyMap[apiName]
  }
}
