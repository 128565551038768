import { Cart } from '@cerifi/ecomm/bigcommerce/types/cart'
import { Product } from '@cerifi/ecomm/bigcommerce/types/product'
import { getLogger } from '@cerifi/logger'

const defaultOptions = {
  autoConfig: true,
  debug: false,
}

type PixelEventName =
  | 'ViewContent'
  | 'AddToCart'
  | 'InitiateCheckout'
  | 'Purchase'
  | 'AddPaymentInfo'
  | 'CompleteRegistration'

interface PixelEventData {
  content_name: string
  content_ids: string[]
  currency: string
  value: number
  status?: boolean // Used with CompleteRegistration Event
  content_type?: string
  content_category?: string
  contents?: { id: string; quantity: number }[]
  num_items?: number // Required for Checkout
}

/**
 * https://developers.facebook.com/docs/meta-pixel/advanced/
 * https://developers.facebook.com/docs/meta-pixel/reference#standard-events
 */
export class MetaPixel {
  private static logger = getLogger('analytics')

  private static _isPixel = (): boolean => {
    return !!window.fbq
  }
  public static ViewContent = (sku: string, name: string, price: number) => {
    if (!this._isPixel()) {
      return
    }

    window.fbq('track', 'ViewContent', {
      content_ids: [sku],
      content_name: name,
      content_type: 'product',
      currency: 'USD',
      value: price,
    })
  }

  public static AddToCart = (sku: string, name: string, price: number) => {
    if (!this._isPixel()) {
      return
    }
    window.fbq('track', 'AddToCart', {
      content_ids: [sku],
      content_name: name,
      content_type: 'product',
      currency: 'USD',
      value: price,
    })
  }

  public static InitiateCheckout = (cart: Cart) => {
    if (!this._isPixel()) {
      return
    }

    window.fbq('track', 'InitiateCheckout', {
      content_ids: cart.lineItems.map((x) => x.sku),
      currency: 'USD',
      value: cart.totalPrice,
      num_items:
        cart?.lineItems?.reduce((sum, x) => sum + (x?.quantity || 0), 0) || 0,
    })
  }

  public static Purchase = (cart: Cart, total: number) => {
    if (!this._isPixel()) {
      return
    }

    window.fbq('track', 'Purchase', {
      content_ids: cart.lineItems.map((x) => x.sku),
      currency: 'USD',
      value: total,
      num_items:
        cart.lineItems.reduce((sum, x) => sum + (x?.quantity || 0), 0) || 0,
    })
  }

  public static AddPaymentInfo = () => {}

  public static CompleteRegistration = () => {
    if (!this._isPixel()) {
      return
    }

    window.fbq('track', 'CompleteRegistration', {
      currency: 'USD',
      status: true,
    })
  }

  public static Track = (eventName: PixelEventName, data: PixelEventData) => {}

  public static PageView = () => {
    window.fbq('track', 'PageView')
  }

  public static RevokeConsent = () => {}
}
