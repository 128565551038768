/** Returns true if you are on the client, false if you are on the server */
export const isClient = () => {
  try {
    // even trying to access window on the server may throw an error, hence the try catch
    if (window !== undefined) {
      return true
    }
  } catch (e) {}

  return false
}
