import { Cart, LineItem } from '@cerifi/ecomm/bigcommerce/types/cart'
import { BCProduct, Product } from '@cerifi/ecomm/bigcommerce/types/product'
import { getLogger } from '@cerifi/logger'

/**
 *
 * @returns hooks related to GA4 Data Layer/gtag
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#search
 */
export class DataLayer {
  private static logger = getLogger('analytics')

  private static _isDataLayer = (): boolean => {
    return !!window.dataLayer
  }

  /**
   * Measure when an item detail page is viewed
   */
  public static ViewItem = (
    id: string,
    sku: string,
    name: string,
    price: number,
    currency: string = 'USD'
  ) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()
    const event = {
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: id,
            item_sku: sku,
            item_name: name,
            currency: 'USD',
            price: price,
          },
        ],
      },
    }
    window.dataLayer.push(event)
  }

  public static SelectPromotion = () => {
    // TODO
  }

  public static ViewPromotion = () => {
    // TODO
  }

  public static SelectItem = (item: Product, listName: string) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()

    const event = {
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_id: item.sku,
            currency: item.price.currencyCode,
            item_name: item.name,
            item_list_name: listName,
            item_list_id: listName,
            price: item.price.currencyCode,
            quantity: 1,
          },
        ],
      },
    }

    window.dataLayer.push(event)
  }

  public static AddToWishList = (item: Product, variantId: string) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()

    const event = {
      event: 'add_to_wishlist',
      ecommerce: {
        items: [
          {
            item_id: item.sku,
            currency: item.price,
            item_name: item.name,
            price: item.price.value,
            variantId: variantId,
          },
        ],
      },
    }

    window.dataLayer.push(event)
  }

  public static AddToCart = (
    item: Product,
    listName: string,
    quantity: number,
    variantId: string
  ) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()

    const event = {
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            item_id: item.sku,
            currency: item.price,
            item_name: item.name,
            item_list_name: listName,
            item_list_id: listName,
            price: item.price.value,
            variantId: variantId,
            quantity: quantity,
          },
        ],
      },
    }

    window.dataLayer.push(event)
  }

  public static RemoveFromCart = (item: LineItem) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()

    const event = {
      event: 'remove_from_cart',
      ecommerce: {
        items: [
          {
            item_id: item.id,
            currency: 'USD',
            item_variant: item.variantId,
            item_name: item.name,
            item_list_name: 'cart list',
            item_list_id: 'cart_list',
            quantity: item.quantity,
          },
        ],
      },
    }

    window.dataLayer.push(event)
  }

  public static BeginCheckout = (cart: Cart) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()

    const eventItems = this.createCheckoutEventItems(cart)

    const event = {
      event: 'begin_checkout',
      ecommerce: {
        items: eventItems,
      },
    }

    window.dataLayer.push(event)
  }

  public static ShippingInfo = (
    cart: Cart,
    value: number,
    shippingTier: string,
    coupon: string
  ) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()

    const eventItems = this.createCheckoutEventItems(cart)

    const event = {
      event: 'add_shipping_info',
      ecommerce: {
        currency: 'USD',
        shipping_tier: shippingTier,
        coupon: coupon,
        value: value,
        items: eventItems,
      },
    }

    window.dataLayer.push(event)
  }

  public static PaymentInfo = (cart: Cart, value: number, coupon: string) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()

    const eventItems = this.createCheckoutEventItems(cart)

    const event = {
      event: 'add_payment_info',
      ecommerce: {
        currency: 'USD',
        coupon: coupon,
        value: value,
        payment_type: 'Credit Card',
        items: eventItems,
      },
    }

    window.dataLayer.push(event)
  }

  public static PurchaseOrRefund = (
    eventName: 'purchase' | 'refund',
    cart: Cart,
    orderId: string,
    tax: number,
    total: number,
    shipping: number,
    coupon?: string
  ) => {
    if (!this._isDataLayer()) {
      return
    }
    this.clearEcommerce()

    const eventItems = this.createCheckoutEventItems(cart)

    const event = {
      event: eventName,
      ecommerce: {
        transaction_id: orderId,
        coupon,
        tax,
        value: total,
        shipping,
        items: eventItems,
      },
    }

    window.dataLayer.push(event)
  }

  private static createCheckoutEventItems = (cart: Cart) => {
    if (!this._isDataLayer()) {
      return
    }
    return cart.lineItems.map((x) => {
      return {
        item_id: x.id,
        currency: 'USD',
        item_variant: x.variant.name,
        item_name: x.name,
        price: x.variant.price,
        quantity: 1,
      }
    })
  }

  public static SendUserId = (hash: string) => {
    if (!this._isDataLayer()) {
      return
    }
    window.dataLayer.push({
      user_id: hash,
    })
  }

  public static Registration = (hash: string) => {
    if (!this._isDataLayer()) {
      return
    }
    window.dataLayer.push({
      event: 'registration',
      authentication_method: 'email',
      user_id: hash,
    })
  }

  public static Login = (hash: string) => {
    if (!this._isDataLayer()) {
      return
    }
    window.dataLayer.push({
      event: 'login',
      authentication_method: 'email',
      user_id: hash,
    })
  }

  public static Search = (searchTerm: string) => {
    if (!this._isDataLayer()) {
      return
    }
    window.dataLayer.push({ event: 'search', search_term: searchTerm })
  }

  public static CustomEvent = (eventName: string, eventObject: any) => {
    if (!this._isDataLayer()) {
      return
    }
    window.dataLayer.push({ event: eventName, eventObject })
  }

  /**
   *  Clear the previous ecommerce object.
   *  Recommended prior to pushing any event to the data layer
   */
  private static clearEcommerce = () => {
    window.dataLayer.push({ ecommerce: null })
  }
}
