import { Cart } from '@cerifi/ecomm/bigcommerce/types/cart'
import { Customer } from '@cerifi/ecomm/bigcommerce/types/customer'
import { getCookie, setCartCookieClient } from '@cerifi/utils/client-cookie'
import { fetcher } from '@cerifi/utils/fetcher'

const mergeCart = async (customer: Customer) => {
  const existingCartId = customer.attributes.cartId?.value

  const guestCartId = process.env.NEXT_PUBLIC_BIGCOMMERCE_CART_COOKIE
    ? getCookie(process.env.NEXT_PUBLIC_BIGCOMMERCE_CART_COOKIE)
    : null

  if (existingCartId) {
    // this customer already has a cart, ignore any guest carts and set the cart to that.
    setCartCookieClient(existingCartId)

    const cart = await fetcher<Cart>('/api/commerce/cart', {
      method: 'GET',
    })

    // if they have a guest cart, merge all that guest cart's items into the customer's existing cart
    if (cart && cart.id && guestCartId && guestCartId !== existingCartId) {
      await fetcher('/api/commerce/cart', {
        method: 'PATCH',
        body: {
          guestCartId: guestCartId,
          cartId: existingCartId,
        },
      })
    } else {
      await associateGuestCart(guestCartId, customer.id)
    }
  } else {
    // check if there is a guest cart, if there is associate this user with that cart.
    await associateGuestCart(guestCartId, customer.id)
  }
}

const associateGuestCart = async (
  guestCartId: string | null | undefined,
  customerId: string
) => {
  if (guestCartId) {
    await fetcher('/api/commerce/customer', {
      method: 'PUT',
      body: {
        cartId: guestCartId,
        customerId: customerId,
      },
    })
  }
}

export { mergeCart, associateGuestCart }
